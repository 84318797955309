.login_button {
    color: #fff;
    height: 100%;
    font-size: 15px;
    padding: .5rem 1.5rem;
    display: flex;
    align-items: center;
    gap: .5rem;
    border: 1px solid var(--colorWhite);
    transition: all 150ms ease-in-out;
    transition-delay: 100ms;
    text-transform: uppercase;
}

.login_button:hover {
    background: linear-gradient(to right, transparent 50%, var(--colorWhite20) 50%) right;
    background-size: 200% 100%;
}

.auth_icon {
    width: 30px;
}

@media screen and (max-width: 639px) {
    .login_button {
        font-size: 16px;
        margin-right: 25px;
    }

    .auth_icon {
        width: 20px;
    }
}
